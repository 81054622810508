import { NAMESPACES } from 'constants/locales'

import React, { Suspense, memo, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import ErrorBoundary from 'components/error-boundary/error-boundary'
import { routes } from 'utils/routes'
import { trackSignUp } from 'components/tracking/utils'
import { MediaTextLayout } from 'components/modules/media-text-module'
import SuspenseFallback from 'components/suspense-fallback'
import dynamic from 'next/dynamic'

import TrackingLocation, {
  TRACKING_LOCATIONS,
} from './common/tracking-location'

const VideoTextModule = dynamic(() =>
  import('./common/media-text-module/media-text-module')
)
const IntegrationsSection = dynamic(() => import('./integrations-section'))
const HeadlineAndTextModule = dynamic(() =>
  import('./common/headline-and-text-module')
)
const ProfilesSection = dynamic(() => import('./profiles-section'))
const WhyTypeformSection = dynamic(() => import('./why-typeform-section'))
const ClosingSection = dynamic(() => import('./closing-section'))

const HomepageBelowFold = () => {
  const { t, lang } = useTranslation(NAMESPACES.HOME)

  const handleSignupClick = useCallback(
    section => () => {
      trackSignUp({
        ctaText: 'Sign up',
        itemType: 'link',
        linkUrl: routes(lang).signup,
        location: `body:${section}`,
      })
    },
    [lang]
  )

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <HeadlineAndTextModule
            hasRichTitle
            title={t('homepage.refreshingly_different.title')}
          />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <TrackingLocation name={TRACKING_LOCATIONS.FOR_THEM}>
          <Suspense fallback={<SuspenseFallback />}>
            <VideoTextModule
              data-testid='for-them-section'
              mediaSlug='for-them'
              layout={MediaTextLayout.MediaRight}
              title={t('homepage.refreshingly_different.for_them.title')}
              body={t('homepage.refreshingly_different.for_them.body')}
              cta={{
                href: routes(lang).signup,
                label: t('homepage.refreshingly_different.for_them.cta.label'),
                onClick: handleSignupClick('for_them'),
              }}
            />
          </Suspense>
        </TrackingLocation>
      </ErrorBoundary>

      <ErrorBoundary>
        <TrackingLocation name={TRACKING_LOCATIONS.FOR_YOU}>
          <Suspense fallback={<SuspenseFallback />}>
            <VideoTextModule
              data-testid='for-you-section'
              mediaSlug='for-you'
              layout={MediaTextLayout.MediaLeft}
              title={t('homepage.refreshingly_different.for_you.title')}
              body={t('homepage.refreshingly_different.for_you.body')}
              cta={{
                href: routes(lang).signup,
                label: t('homepage.refreshingly_different.for_you.cta.label'),
                onClick: handleSignupClick('for_you'),
              }}
            />
          </Suspense>
        </TrackingLocation>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <IntegrationsSection />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <HeadlineAndTextModule
            title={t('homepage.templates.title')}
            description={t('homepage.templates.description')}
          />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <ProfilesSection />
        </Suspense>
      </ErrorBoundary>

      {lang === LANGUAGE_CODES.english && (
        <ErrorBoundary>
          <Suspense fallback={<SuspenseFallback />}>
            <WhyTypeformSection />
          </Suspense>
        </ErrorBoundary>
      )}

      <ErrorBoundary>
        <Suspense fallback={<SuspenseFallback />}>
          <ClosingSection />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

HomepageBelowFold.displayName = 'HomepageBelowFold'

export default memo(HomepageBelowFold)
